<template>
  <div class="relatorio-dre-init">
    <v-container fluid>
      <h1 class="titulo">Resultados Loterias</h1>

      <v-form>
        <v-row>
          <v-col md="2">
            <v-menu :close-on-content-click="true" :nudge-right="35" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on }">
                  <!-- <v-date-picker v-model="date" @change="getLotteriesGroups(date)"/> -->
                <v-text-field
                  v-model="dtInicialString"
                  v-mask="['##/##/####']"
                  :label="$gettext('Data Inicial')"
                  @input="testeDate('inicial', dtInicialString)"
                  class="mt-2"
                  dense
                  v-on="on">
                  <template v-slot:append>
                    <v-icon v-on="on">
                      event
                    </v-icon>
                  </template>
                </v-text-field>
              </template>

              <v-date-picker
                v-model="date"
                no-title
                scrollable
                locale="pt-br"
                :type="'date'"
                @input="closeModalDatePicker('inicial', date)"
                :color="variables.colorPrimary">
                  <v-spacer></v-spacer>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col md="2" class="pt-0">
            <v-select
              :items="lotteries"
              label="Loteria"
              v-model="selectedLottery"
            />
          </v-col>

          <!-- <v-col md="1">
            <v-text-field v-model="region" label="Região" filled :error-messages="regionErrors" @blur="$v.region.$touch()" readonly></v-text-field>
          </v-col> -->
        <v-col class="d-flex">
          <v-btn class="mt-1" @click.prevent="submit(selectedLottery)" color="info" :loading="isLoading" @mouseover="visible = true">Buscar</v-btn>
        </v-col>
        </v-row>
      </v-form>
      <v-row>
      </v-row>

      <v-row v-if="!hasResults">
        <h1>Nenhum resultado para mostrar.</h1>
      </v-row>

      <v-row v-else>
        <v-col cols="12">
          <template v-for="g in results.drawn.data.result">
            <div :key="`${g}-bolaNum`" class="result-bingo-number winners">
              <span class="result-bingo-number"> {{ g }} </span>
              <img src="@/assets/img/miniLaranja.png" class="image">
            </div>                        
          </template>
        </v-col>

        <v-row>
          <v-col cols="4" v-if="listGanhadores('coluna')">
            <TabelaGanhadores :listPremios="listGanhadores('coluna')"  :results="results.drawn.data.colWinners" />
          </v-col>
          <v-col cols="4" v-if="listGanhadores('linha')">
            <TabelaGanhadores :listPremios="listGanhadores('linha')" :results="results.drawn.data.lineWinners" />
          </v-col>
          <v-col cols="4" v-if="listGanhadores('transversal')">
            <TabelaGanhadores :listPremios="listGanhadores('transversal')" :results="results.drawn.data.transversalWinners" />
          </v-col>
          <v-col cols="4" v-if="listGanhadores('bingo')">
            <TabelaGanhadores :listPremios="listGanhadores('bingo')" :results="results.drawn.data.allWinners" />
          </v-col>
          <v-col cols="4" v-if="listGanhadores('extra')">
            <TabelaGanhadores :listPremios="listGanhadores('extra')"  />
          </v-col>
        </v-row>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import variables from '@/assets/styles/helpers/_variables.scss'
import { mask } from 'vue-the-mask'
import { size } from 'lodash'

const namespaceStore = 'relatorioResultadosBingo'
export default {
  name: 'ResultadoLoteriasBingos',
  directives: {
    mask
  },   
  
  components: {
    TabelaGanhadores: () => import('./components/tabelaGanhadoes'),
  },

  data: () => ({
    selectedLottery: '',
    dtInicialString: moment().format('DD/MM/YYYY'),
    date: moment().format('YYYY-MM-DD'),
    region: 'GYN',
    isLoading: false
  }),
  watch: {
    'date' (data) {
      this.getLotteriesGroups(data)
      this.clearData()
      this.limparLoterias()
    }
  },

  mounted () {
    let self = this
    self.$v.region.$touch()
    self.getLotteriesGroups(self.date)
    // self.selectedLottery = self.lotteries
    // self.submit(self.selectedLottery, self.date)
  },
  computed: {
    ...mapGetters(namespaceStore, ['results', 'lotteries', 'ganhadores']),
    variables: () => variables,
    dateErrors () {
      const errors = []
      if (!this.$v.date.$dirty) return errors
      !this.$v.date.required && errors.push('Data é obrigatório.')
      return errors
    },
    regionErrors () {
      const errors = []
      if (!this.$v.region.$dirty) return errors
      !this.$v.region.required && errors.push('Região é obrigatório.')
      return errors
    },

    hasResults () {
      return size(this.results)
    }
  },
  methods: {
    closeModalDatePicker(dt, date) {
      this.dtInicialString = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    ...mapActions(namespaceStore, ['getSweepstakesResults', 'getLotteries', 'limparResultado', 'limparLoterias']),
    submit (cod) {
      let self = this
      if (!self.$v.$invalid) {
        self.isLoading = true
        self.getSweepstakesResults({ idBingo: cod }).then( async () => {
          self.isLoading = false
        }).catch(() => {
          this.clearData()
          self.isLoading = false
        })
      }
    },
    getLotteriesGroups (date) {
      let self = this
      self.getLotteries({date}).then(() => {
        self.selectedLottery = self.lotteries[0]
      })
    },

    verifyResult (drawn, v) {
      if (!drawn) return
      return drawn.data.result.includes(String(v).padStart('0', 2))
    },

    listGanhadores (type) {
      switch (type) {
        case ('coluna'):
          return (size(this.ganhadores) && size(this.ganhadores.premioColuna)) && this.ganhadores.premioColuna[0]
        case ('linha'):
          return (size(this.ganhadores) && size(this.ganhadores.premioLinha)) && this.ganhadores.premioLinha[0]
        case ('bingo'):
          return (size(this.ganhadores) && size(this.ganhadores.premioCartelaFechada)) && this.ganhadores.premioCartelaFechada[0]
        case ('transversal'):
          return (size(this.ganhadores) && size(this.ganhadores.premioTransversal)) && this.ganhadores.premioTransversal[0]
        case ('extra'):
          return (size(this.ganhadores) && size(this.ganhadores.premioExtra)) && this.ganhadores.premioExtra[0]
        default:
          return false
      }
    },

    clearData () {
      this.limparResultado()
    }
  },
  validations () {
    return {
      date: { required },
      region: { required }
    }
  },

  beforeDestroy () {
    this.clearData()
  }
}
</script>

<style lang="scss" scoped>
.result-bingo {
  &-dividir {
    margin: 10px 0px;
    border: 1px solid #EE7D00
  }
  &-title {
    color:#EE7D00;
    font-size: 18px;
    span {
      font-weight: bold;
      padding-left: 3px;
      padding-right: 3px;
    }
  }
  &-title-bingo {
    color:#EE7D00;
    font-size: 16px;
    font-weight: bold;
    display: block;
    width: 100%;
    text-align: center;
  }
  &-number {
    position: relative;
    display: inline-block;
    span {
      position: absolute;
      font-weight: bold;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
      color: #666;
      opacity: 0.5;
    }
    img {
      max-width: 100%;
      opacity: 0.1;
    }
  }
  &-number.winners {
    span {
      color: #EE7D00;
      display: initial !important;
      opacity: 1;
      font-weight: bold;
    }
    img {
      display: initial !important;
      opacity: 1;
    }
  }

  &-table {
    display: inline-block;
    font-weight: bold;
    color: #3d80b4 !important;
    tbody tr td {
      color: #3d80b4 !important;
      &.bola{
        position: relative;
        padding: 0;
        text-align: center;
        span{
          position: absolute;
          font-weight: bold;
          z-index: 1;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 14px;
          color: #3d80b4;
        }
      }
    }
  }
}
</style>